.sizzle {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  video {
    width: 100%;
    height: auto;
  }
}