.player-registration-form {
  position: relative;
  padding: 0 45px 30px;

  h1 {
    margin: 0;
    font-family: 'GenesisSansHeadLight';
    letter-spacing: 1.5px;
    font-size: 36px;
    margin-bottom: 30px;
  }
  form input[type="submit"]{
    font-size: 18px;
    height: 40px;
    padding: 0 74px;
    background-color: white;
    border: 1px solid black;
    border-radius: 0;
    display: inline-block;
    margin: 0 auto 10px;
    text-transform: uppercase;
    color: black;
  }
  .submit {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    border-radius: 0;
  }
  .disable-submit {
    pointer-events: none;
  }
  .field-group {
    display: flex;
    margin-bottom: 40px;
  }
  .field-group.last {
    margin-bottom: 16px;
  }
  .fieldset {
    flex-grow: 1;
    display: inline-block;
    margin-right: 30px;
    label, input {
      display: block;
    }
    label {
      font-family: 'GenesisSansHead';
      font-size: 16px;
      margin-bottom: 10px;
    }
    input {
      height: 35px;
      background-color: #eff0f0;
      border: none;
      width: 100%;
      height: 45px;
      padding: 6px;
      outline: none;
      font-family: "GenesisSansTextMedium";
      font-weight: lighter;
      font-size: 20px;
    }
  }
  .cancel {
    margin-top: 40px;
    a:visited {
      color: black;
    }
  }
  .field-group.toggle {
    display: block;
  }
  .fieldset.toggle {
    padding-top: 24px;
    width: 100%;
    text-align: left;
    label, input {
      font-family: 'GenesisSansTextMedium';
      display: inline-block;
      margin-right: 20px;
      font-size: 16px;
    }
    label {
      width: auto;
    }
    button {
      width: 60px !important;
      height: 34px !important;
      color: black !important;
      background-color: white !important;
      border-radius: 0 !important;
    }
    button.Mui-selected {
      color: white !important;
      background-color: black !important;
    }
  }
  .fieldset.select {
    div.MuiBox-root > div > div {
      border-radius: 0 !important;
      border: 1px solid black !important;
      outline: none !important;
      height: 35px !important;
    }
    label {
      position: relative;
      top: 8px;
      width: auto;
      font-family: 'GenesisSansTextMedium';
      font-size: 16px;
      margin-right: 20px;
      display: inline-block;
    }
    .select {
      width: 40%;
      display: inline-block;
      em {
        font-style: normal;
      }
    }
  }
  .field-group.checkbox {
    width: calc(100% - 40px);
    margin-bottom: 10px;
  }
  .fieldset.checkbox {
    position: relative;
    svg {
      color: black !important;
      height: 40px;
      width: 40px;
    }
    a:visited {
      color: black;
    }
    .emphasize {
      font-style: italic;
    }
    .text {
      position: relative;
    }
    .text.error-checkbox::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -45px;
      height: 33px;
      width: 33px;
      border: 2px solid #ff0000;
    }
    .MuiFormControlLabel-root {
      .MuiTypography-root {
        position: absolute;
        top: 12px;
        font-size: 14px;
      }
    }
  }
  .fieldset.error-required {
    input {
      border: 1px solid #ff0000;
    }
  }
  .error-message {
    color: #ff0000;
    font-size: 10px;
  }
  .required-key-message {
    font-size: 10px;
  }
  .duo-checkbox-field-group {
    display: flex;
  }
  .field-group.checkbox.one,
  .field-group.checkbox.two,
  .field-group.checkbox.three{
    height: 120px;
    top: 10px
  }
  .disabled18{
    pointer-events: none;
    color: lightgray;
    div.MuiBox-root > div > div {
      border: 1px solid lightgray !important;
      color: lightgray;
    }
  }
}
