.frame-button {
    font-size: 18px;
    height: 40px;
    padding: 0 74px;
    background-color: white;
    border: 1px solid black;
}

.link-button {
    font-size: 18px;
    height: 40px;
    padding: 0 74px;
    background-color: white;
    border: none;
    text-decoration: underline;
}

.list-header {
    width: 100%;
    height: 32px;
    background-color: black;
    font-family: "GenesisSansTextMedium";
    font-size: 10px;
    font-weight: bold;
    line-height: 32px;
    padding-left: 20px;
    padding-top: 2px;
    letter-spacing: 1px;
    color: white;
}

.list-item {
    position: relative;
    text-align: center;
    font-family: "GenesisSansHead";
    font-size: 50px;
    text-transform: uppercase;
    span {
        display: block;
        height: 100%;
        line-height: 1.2em;
        margin: 18px 0 10px 0;
        width: 80%;
        text-align: left;
    };
    span:first-child {
        width: 20%;
        text-align: center;
    }
}

.list-item.hero {
    margin-top: 0;
    padding-bottom: 1em;
    padding-top: 0.5em;
    span{
        line-height: 1.2em;
        margin: auto;
        max-width: 400px;
        text-align: center;
    }
}

.checkbox-icon, .checkbox-icon-checked {
    width: 35px;
    height: 35px;
    border: 1px solid black;
    background-color: white;
}
.checkbox-icon-checked {
    background-color: black;
}
.checkbox-error{
    border: 1px solid #ff0000 !important;
}
