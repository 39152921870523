/* Webfont: GenesisSansHead */@font-face {
  font-family: 'GenesisSansHead';
  src: url('/fonts/GenesisSansHead.eot'); /* IE9 Compat Modes */
  src: url('/fonts/GenesisSansHead.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/GenesisSansHead.woff') format('woff'), /* Modern Browsers */
  url('/fonts/GenesisSansHead.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/GenesisSansHead.svg#GenesisSansHead') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GenesisSansHead-Light */@font-face {
  font-family: 'GenesisSansHeadLight';
  src: url('/fonts/GenesisSansHead-Light.eot'); /* IE9 Compat Modes */
  src: url('/fonts/GenesisSansHead-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/GenesisSansHead-Light.woff') format('woff'), /* Modern Browsers */
  url('/fonts/GenesisSansHead-Light.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/GenesisSansHead-Light.svg#GenesisSansHead-Light') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GenesisSansText-Bold */@font-face {
  font-family: 'GenesisSansText';
  src: url('/fonts/GenesisSansText-Bold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/GenesisSansText-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/GenesisSansText-Bold.woff') format('woff'), /* Modern Browsers */
  url('/fonts/GenesisSansText-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/GenesisSansText-Bold.svg#GenesisSansText-Bold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: GenesisSansText-BoldItalic */@font-face {
  font-family: 'GenesisSansText';
  src: url('/fonts/GenesisSansText-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('/fonts/GenesisSansText-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/GenesisSansText-BoldItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/GenesisSansText-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/GenesisSansText-BoldItalic.svg#GenesisSansText-BoldItalic') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: GenesisSansText-Italic */@font-face {
  font-family: 'GenesisSansText';
  src: url('/fonts/GenesisSansText-Italic.eot'); /* IE9 Compat Modes */
  src: url('/fonts/GenesisSansText-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/GenesisSansText-Italic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/GenesisSansText-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/GenesisSansText-Italic.svg#GenesisSansText-Italic') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GenesisSansText-Medium */@font-face {
  font-family: 'GenesisSansTextMedium';
  src: url('/fonts/GenesisSansText-Medium.eot'); /* IE9 Compat Modes */
  src: url('/fonts/GenesisSansText-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/GenesisSansText-Medium.woff') format('woff'), /* Modern Browsers */
  url('/fonts/GenesisSansText-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/GenesisSansText-Medium.svg#GenesisSansText-Medium') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GenesisSansText-MediumItalic */@font-face {
  font-family: 'GenesisSansTextMedium';
  src: url('/fonts/GenesisSansText-MediumItalic.eot'); /* IE9 Compat Modes */
  src: url('/fonts/GenesisSansText-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/GenesisSansText-MediumItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/GenesisSansText-MediumItalic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/GenesisSansText-MediumItalic.svg#GenesisSansText-MediumItalic') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
