.player-queue {
  position: relative;
  padding: 96px 110px 0px;
  overflow: hidden;
  height: 100%;

  .queue {
    border: 1px solid black;
    max-width: 1700px;
    margin: auto;
    height: 100%;

    .screen {
      padding: 36px;
    }
  }

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 400px;
    li{
      list-style-type: none;
      width: 50%;
    }
  }

  .list-header {
    span {
      display: block;
      height: 100%;
      line-height: 30px;
    }
  }

  .players-list-trail, .players-list-full {
    ul {
      max-height: 640px;
    }
  }

  .players-list-trail {
    ul {
      height: 640px;
    }
  }

  .header {
    display: flex;
    height: 80px;
    margin-bottom: 40px;

    .brand {
      padding: 0;
      width: 190px;
      border-right: 1px solid black;

      img {
        width: 160px;
        height: auto;
        margin-top: 20px;
      }

      .text {
        margin-top: 4px;
        font-size: 14px;
        text-align: left;
        padding-left: 45px;
        letter-spacing: 0.5px;

        p {
          margin: 0;
          font-family: "GenesisSansTextMedium";
          font-size: 1em;
          line-height: 14px;
        }
      }
    }

    .title-1, .title-2 {
      position: relative;
      top: 24px;
      font-family: 'GenesisSansHead';
      font-size: 30px;
      flex-grow: 2;
      padding: 0 20px;
      letter-spacing: 1px;
    }
    .title-1 {
      padding-left: 28px;
    }
    .title-2 {
      text-align: right;
    }
  }

  .top-players{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 36px;
    height: 230px;
    .column{
      width: calc(50% - 18px);
      background-color: black;
      color: white;
    }
    .list-item{
      background-image: none;
    }
    .hero {
      font-size: 50px;
      flex-direction: column;
    }
  }
  .list-item{
    display: flex;
    background-image: linear-gradient(to right, black 20%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 7px 1px;
    background-repeat: repeat-x;
    font-size: 30px;
    span:first-child {
      width: 94px;
    }
    span:last-child {
      padding-left: 90px;
    }
  }
  .current-players {
    position: relative;
  }
  .current-players::after {
    position: absolute;
    top: 0;
    left: calc(50% - 20px);
    content: '';
    background-color: white;
    width: 40px;
    height: 100%;
  }
  .player-management-timer {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
  }

  //.dev-frame {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 1920px;
  //  height: 1080px;
  //  border: 1px solid black;
  //  background: transparent;
  //}

}
