.navbar {
  height: auto;
  text-align: right;
  span {
    margin-left: 20px;
  }
  .navbar-auth-true {
    position: relative;
    .header{

    }
  }
  .back {
    position: absolute;
    left: -20px;
  }
  .back.hide {
    display: none;
  }
}

.navbar.hide {
  display: none;
}

.navbar-return {
  position: relative;
  height: 120px;
  padding-top: 50px;
  font-family: 'GenesisSansTextMedium';
  vertical-align: middle;
  span {
    font-family: 'GenesisSansHead';
    font-size: 20px;
    letter-spacing: 1.5px;
  }
  img {
    margin-right: 10px;
  }
  .brand {
    position: absolute;
    top: 32px;
    right: 0;
    text-align: center;
    > div > img {
      margin-top: 10px;
    }
  }
  a {
    text-decoration: none;
  }
  a:visited {
    color: black;
  }
}