.shareable {
  position: relative;
  height: 100%;
  padding: 0 63px;
  overflow: scroll;
  background-color: #000;
  color: #fff;

  .header {
    max-width: 1700px;
    text-align: center;

    .brand {
      display: inline-block;
      position: relative;
      width: 136px;
      margin: 40px auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .asset-container {
    border: 1px solid #a36b4f;
    border-bottom: none;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0 10px;
    overflow: hidden;

    .title-1 {
      font-family: 'GenesisSansHeadLight', sans-serif;
      font-size: 25px;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin: 50px auto 25px;
      font-weight: normal;
    }

    .media {
      display: flex;
      font-family: 'GenesisSansText', sans-serif;
      justify-content: center;

      .video,
      .distance {
        width: 30%;
      }

      .video {
        .video-player {
          width: 50%;
          height: auto;
          max-width: 300px;
          border: 1px solid #a36b4f;
          margin-bottom: 20px;
          position: relative;
        }
        .share-bubble {
          text-align: center;
          border-radius: 4px;
          background-color: #fff;
          width: 150px;
          padding: 15px 32px;
          margin: 0 auto;
          height: 74px;
          position: absolute;
          bottom: 70px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: space-around;

          .pointer {
            position: absolute;
            margin: 0 auto;
            transform: rotate(45deg);
            bottom: -10px;
            height: 25px;
            width: 25px;
            background-color: #fff;
          }
        }
        button {
          background-color: transparent;
          text-align: center;
          border: none;
          text-decoration: none;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-family: 'GenesisSansHead', sans-serif;

          &.primary {
            display: block;
            color: white;
            padding: 10px 0px;
            margin: 10px auto;
            width: 40%;
            position: relative;
          }

          &.primary {
            border: 1px solid #a36b4f;
            &:hover {
              background-color: #a36b4f;
              color: #000;
              transition: background-color 200ms ease;
            }
          }
        }
        .download {
          text-decoration: none;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-family: 'GenesisSansHead', sans-serif;
          display: block;
          color: white !important;
          padding: 10px 0px;
          margin: 10px auto;
          width: 40%;
          position: relative;
          margin-bottom: 30px;
          &:hover {
            background-color: #a36b4f;
            color: #000;
            transition: background-color 200ms ease;
          }
        }
      }

      .distance {
        .distance-icon {
          margin-top: 10%;
        }

        .stat {
          font-size: 65px;
          font-family: 'GenesisSansHead', sans-serif;
          margin: 30px auto 0;
        }
      }

      .subtitle {
        text-transform: uppercase;
        letter-spacing: 3px;
        border-bottom: 2px solid #a36b4f;
        display: inline-block;
        padding-bottom: 6px;
        font-weight: normal;
        font-family: 'GenesisSansHead', sans-serif;
        margin: 10px 0;
      }
    }

    footer {
      .tags {
        font-weight: bold;
        letter-spacing: 2px;
        font-size: 16px;
        border-bottom: 1px solid #a36b4f;
        padding-bottom: 10px;
        display: inline-block;
        width: fit-content;
      }

      .disclaimer {
        opacity: 50%;
        font-size: 12px;
        letter-spacing: 1px;
        padding-bottom: 100px;
      }
    }
  }

  @media (max-width: 780px) {
    .asset-container {
      border: none;
      width: 100%;
      padding: 0;
      overflow: visible;

      .title-1 {
        margin-top: 0px;
      }

      .media {
        flex-direction: column;

        .video,
        .distance {
          width: 100%;
        }
        .video {
          .video-player {
            width: 85%;
            height: auto;
            max-width: 300px;
            border: 1px solid #a36b4f;
            margin: 0 auto 20px;
          }
          button.primary,
          .download {
            width: 60%;
          }
        }

        .distance {
          .stat {
            font-size: 55px;
            margin: 30px auto 15px;
          }
        }
      }

      footer {
        .tags {
          line-height: 30px;
          margin-top: 125px;
        }
        .disclaimer {
          font-size: 10px;
        }
      }
    }
  }
}
