.leaderboard {
  position: relative;
  height: 100%;
  padding: 48px 110px 0px;
  overflow: hidden;
  font-family: 'GenesisSansTextMedium';

  table {
    border-collapse: collapse;
    border: none;
  }
  tr {
    position: relative;
  }
  tr::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(to right, black 20%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 7px 1px;
    background-repeat: repeat-x;
  }
  th, td {
    padding-left: 30px;
  }
  th:last-child {
    text-align: center;
  }

  .header {
    display: flex;
    height: 80px;
    margin-bottom: 40px;

    .brand {
      padding: 0;
      width: 190px;
      border-right: 1px solid black;

      img {
        width: 160px;
        height: auto;
        margin-top: 20px;
      }

      .text {
        margin-top: 4px;
        font-size: 14px;
        text-align: left;
        padding-left: 45px;
        letter-spacing: 0.5px;

        p {
          margin: 0;
          font-family: "GenesisSansTextMedium";
          font-size: 1em;
          line-height: 14px;
        }
      }
    }

    .title-1, .title-2 {
      position: relative;
      top: 24px;
      font-family: 'GenesisSansHead';
      font-size: 30px;
      flex-grow: 2;
      padding: 0 20px;
      letter-spacing: 1px;
    }
    .title-1 {
      padding-left: 28px;
    }
    .title-2 {
      text-align: right;
    }
  }

  .content {
    height: 100%;
    border: 1px solid black;
    padding: 50px 50px 0 50px;
  }

  .player-list {
    margin: auto;
    height: 100%;
    table-layout: auto;
    width: 100%;


    .screen {
      padding: 36px;
    }

    .board-header {
      font-family: 'GenesisSansHeadLight';
      color: white;
      background-color: #2f2f2f;
      padding: 0;
      margin: 0;
      height: 48px;
      font-size: 14px;
      letter-spacing: 1px;
    }

    .player {
      font-size: 23px;
      letter-spacing: 1.2px;
      font-family: 'GenesisSansHead';
      height: 96px;
      max-height: 96px;
      td {
        height: 96px;
        max-height: 96px !important;
      }
      td:first-child {
        span {
          width: 140px;
          display: block;
          text-align: center;
        }
      }
      td:last-child {
        span {
          width: 100%;
          display: block;
          text-align: center;
        }
      }
    }

    .leader {
      background-color: #cacbcc;
    }
  }

  .dev-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 1920px;
    height: 1080px;
    border: 1px solid black;
    background: transparent;
  }

}

