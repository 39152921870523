.player-registration-complete {
  padding-top: 280px;
  position: relative;
  height: 100%;
  .header {
    width: fit-content;
    margin: 0 auto 36px;
    .brand {
      display: inline-block;
      position: relative;
      width: 136px;
      img {
        width: 100%;
        height: auto;
        margin-top: 8px;
      }
      .text {
        margin-top: 4px;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.5px;
        p {
          margin: 0;
          font-family: "GenesisSansTextMedium";
          font-size: 1em;
          line-height: 14px;
        }
      }
    }
    .brand::after {
      position: absolute;
      content: "";
      height: 58px;
      width: 1px;
      background-color: black;
      right: -30px;
      top: 0;
    }
    .title{
      font-family: 'GenesisSansHeadLight';
      font-size: 21px;
      display: inline-block;
      vertical-align: middle;
      letter-spacing: 3.4px;
      margin-left: 60px;
      margin-bottom: 40px;
    }
  }
  .success, .line-place, .count, .new-signup, .close {
    width: 100%;
    text-align: center;
  }

  .success {
    font-family: "GenesisSansTextMedium";
    margin-top: 80px;
    font-size: 20px;
    letter-spacing: 1px;
  }

  .line-place {
    font-family: "GenesisSansTextMedium";
    margin-top: 6px;
    font-size: 14px;
  }

  .count {
    font-size: 52px;
  }

  .new-signup {
    position: absolute;
    bottom: 155px;
    font-family: "GenesisSansTextMedium" !important;
    button{
      font-family: "GenesisSansTextMedium" !important;

      margin-bottom: 15px;
    }
  }

  .close {
    position: absolute;
    bottom: 40px;
    font-family: "GenesisSansTextMedium";
    a:visited {
      color: black;
    }
  }

}