.legal{
    padding: 0 70px;
    h1 {
        margin: 0;
        font-family: 'GenesisSansHeadLight';
        font-size: 28px;
        margin-bottom: 20px;
    }
    .notes{
        overflow-y: scroll;
        height:776px;
        -webkit-overflow-scrolling: touch;
    }
}

