.dashboard {
  position: relative;
  padding: 40px 40px 0;
  max-height: 1140px;
  overflow: hidden;

  h1 {
    margin-bottom: 60px;
  }

  h2 {
    margin-top: 80px;
  }

  font-family: 'GenesisSansHead';
  .controls {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
      height: 80px;
      font-weight: bold;
      font-size: 1.5em;
    }
  }
  .player {
    span {
      font-size: 22px;
      margin-right: 6px;
    }
  }
  .players {
    button {
      pointer-events: none;
    }
    h3 {
      background-color: black;
      color: white;
      padding: 10px;
      margin-top: 0;
      margin-bottom: 0;
    }
    .active-player, .next-player, .waiting-players {
      button, span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .active-player .player, .next-player .player {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .waiting-players .player {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    ul {
      padding: 0;
      li {
        list-style-type: none;
      }
    }
    .player {
      padding-left: 20px;
      button {
        width: 40px;
        background: none;
        border: none;
        margin-right: 40px;
      }
    }
    .waiting-players {
      ul {
        height: 450px;
        overflow: scroll;
      }
      .index {
        margin-left: 10px;
        margin-right: 70px;
      }
    }
  }
  .player-management-timer {
    position: absolute;
    top: 314px;
    right: 50px;
    width: 100%;
    text-align: right;
    z-index: 10;
    button {
      padding-right: 0;
      padding-left: 20px;
    }
  }
}
