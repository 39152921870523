.player-registration {

  padding-top: 350px;

  vertical-align: middle;

  font-family: 'GenesisSansTextMedium';

  span {
    display: inline-block;
  }

  img[alt="brand image"] {
    font-size: 10px;
  }

  .brand {
    text-align: center;
    height: 64px;
    img {
      margin-top: 10px;
    }
  }

  .title {
    line-height:64px;
    height: 64px;
  }

  .brand-title {
    display: flex;
    justify-content: center;
  }

  .pipe {
    width: 1px;
    height: 64px;
    background-color: black;
    margin: 0 52px;
  }

  .sign-up {
    margin-top: 110px;
    text-align: center;
    .frame-button {
      width: 300px;
    }
  }

  .admin{
    margin-top: 320px;
    text-align: center;
  }
}
