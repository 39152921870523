* {
  box-sizing: border-box;
}

html, body, #root, .app {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

button, input[type="submit"] {
  cursor: pointer;
}

form input[type="submit"] {
  -webkit-appearance:none;
  -webkit-border-radius:0;
  color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.app {
}
a{
  color: black !important;
}

