.video-container{
  width: 100vw;
  height: 100vh;
}
    
.video-container iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}
.border{
  border: 60px solid white;
  // border: 60px solid black;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.livestream-fixed{
  position: fixed;
z-index: 1000;
height: 60px;
padding: 20px;
display: flex;
justify-content: center;
align-items: center;
font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
font-size: 20px;
}
.livestream-top{
top: 0;
left: 0;
}
.livestream-bottom{
  bottom: 0;
  right: 0;
}