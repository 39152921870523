.auth {
  input, label {
    display: block;
    width: 100%;
  }

  input {
    height: 30px;
  }

  h1 {
    text-align: center;
  }

  input[type="submit"] {
    margin-top: 30px;
    width: 100%;
    height: 40px;
  }

  label {
    margin-bottom: 4px;
  }

  .fieldset {
    text-align: left;
    display: block;
      width: 400px;
    margin-bottom: 20px;
  }

  .login, .register {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
  }
}
