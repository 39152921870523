.player-management {
  padding: 20px 60px
}

.title {
  font-family: 'GenesisSansHeadLight';
  font-size: 30px;
  margin-bottom: 20px;
}

.manage-player-queue {
  padding: 0 60px;

  ul {
    list-style-type: none;
    padding: 0;
  }

  .drag-icon {
    position: absolute;
    right: 50px;
  }

  li {

  }

  .locked {
    pointer-events: none;
    background-color: aliceblue;
    height: 80px;
    margin-bottom: 20px;
  }

  .draggable, .static {
    height: 80px;
    border: 1px solid black;
    margin: 0.5em 0em;
  }
  .static {
    padding-top: 18px;
    border: none;
  }

  .draggable {
    padding: 0;
    list-style-type: none;
    color: black;
    border: none;
    border-bottom: 1px solid black;
    .player {
      padding-top: 24px;
      .draggable-target {
        position: absolute;
        right: 10px;
      }
      .move-to-on-deck {
        position: absolute;
        right: 250px;
      }
      .move-to-trash {
        position: absolute;
        right: 130px;
      }
      .move-from-line {
        position: absolute;
        right: 180px;
      }
    }
  }

  .player-indexed, .player-non-indexed {
    font-family: "GenesisSansTextMedium";
    font-size: 22px;
    text-transform: uppercase;
    margin: 15px;
    padding: 10px;
  }

  .player-indexed {
    position: relative;
    margin-left: 14px;
  }

  .player-non-indexed {
    margin-left: 0;
  }

  .current-player, .next-player, .upcoming-players {
    font-family: 'GenesisSansTextMedium';
    background: black;
    color: white;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .player-list {
    position: relative;
    ul {
      max-height: 620px;
      overflow: scroll;
      padding: 0;
    }
  }

  .player {
    position: relative;
    button{
      display: inline-block;
      vertical-align: middle;
      border: none;
      background-color: transparent;
    }
    .player-name {
      padding-left: 20px;
    }
    .flag-button {
      position: absolute;
      right: 10px;
    }
  }
  .static{
    .trash-button {
      position: absolute;
      right: 10px;
    }
    .player-distance {
      position: absolute;
      right: 180px;
      text-align: right;
    }
  }

  .delete-player-modal,
  .deactivate-player-modal,
  .activate-player-modal,
  .on-deck-player-modal,
  .record-distance-modal {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    .modal-frame {
      width: 70%;
      min-height: 300px;
      border: 1px solid black;
      background-color: white;
      margin: 60px auto 0;
      border-radius: 8px;
      padding: 20px;
      text-align: center;
      .control-buttons {
        margin-top: 60px;
        > div {
          margin-top: 30px;
        }
      }
    }
  }

  .deactivate-player-modal {
    .modal-frame {
      height: 390px;
    }
  }

  .on-deck-player-modal {
    .modal-frame {
      height: 370px;
      .control-buttons {
        margin-top: 110px;
      }
    }
  }

  .record-distance-modal {
    input, span {
      height: 60px;
      font-size: 47px;
      width: 80px;
      text-align: right;
      border: none;
    }
    input {
      border-bottom: none;
    }
    input[type="submit"] {
      border: 1px solid black;
      background-color: white;
      text-transform: uppercase;
      width: 200px;
      font-size: 18px;
      text-align: center;
      margin-bottom: 30px;
    }
    .submit-btn {
      margin-top: 60px;
    }
    .modal-frame {
      height: 440px;
    }
    .closest-distance {
      margin-top: 5px;
    }
    .entry-fields {
      border-bottom: 1px solid black;
      width: 200px;
      display: inline-block;
    }
  }

  .player-management-timer {
    position: absolute;
    width: 100%;
    text-align: right;
    padding-top: 12px;
    button {
      padding-right: 0;
      padding-left: 20px;
    }
  }
}
