.player-exists-modal, .player-under18-modal, {
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 25%;
  background-color: rgba(255, 255, 255, 0.75);
  .modal-frame {
    width: 85%;
    border: 1px solid black;
    background-color: white;
    margin: 60px auto 0;
    border-radius: 8px;
    padding: 60px 20px 0px 20px;
    text-align: center;
    h1 {
      font-family: "GenesisSansText";
      font-size: 30px;
    }
    p {
      font-family: "GenesisSansText";
      font-style: italic;
    }
    .control-buttons {
      margin-top: 60px;
      margin-bottom: 40px;
      button {
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      > div {
        margin-top: 30px;
      }
    }
  }
}